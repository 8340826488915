import {Howl} from 'howler';
import Logger from '../../BaseClasses/Logger';
//
// This needs to be withFirebase and it needs to get notifications about sounds
// to be played. Additionally "playSound" should tell FIrebase that a sound
// is being played, at least while in multi-player mode

class SoundManager extends Logger {

  static PLAYED = 1;
  static QUEUED = 2;
  static NOT_PLAYED = 3;


  constructor(subfolder='') {
    super('SoundManager');
    this.doLogging = false;  // Value in Logger to set to do logging

    this.state = {soundFile:'nofile'};
    this.folderBase ='/sounds/';
//    this.donePlayingCB = null;
    this.soundSprites = {};
    this.sounds = {};
    this.soundQueue = [];
    this.soundCurrentlyPlaying = ""; // Name of sound curently playing
    if(subfolder !== '') {
      this.folderBase = this.folderBase + subfolder + '/';
    }
    this.soundsPlaying = [];  // When a sound starts it is pushed into this array.

  }

  isAnySoundPlaying = () => {
    return (this.soundsPlaying.length > 0);
  };

  removeSoundFromList = (snd) => {
     let found = -1;
     let evt = null;
     for(let i=0;i<this.soundsPlaying.length;i++) {
       if(this.soundsPlaying[i].id === snd) {
         found = i;
         evt = this.soundsPlaying[i].event;
         this.log("removeSoundFromList", "evt="+evt);
         break;
       }
     }
     if(found >= 0) {
       this.soundsPlaying.splice(found,1);  // Remove element from the array
     }
     return evt;
  };
//
// Is there a sound currently playing that matches this key/name?
  isSoundInList = (type,key,name=null) => {
    let ret = false;
    for(let i=0;i<this.soundsPlaying.length;i++) {
      if(type === 'sprite' && this.soundsPlaying[i].key === key && this.soundsPlaying[i].sprite === name) {
        ret = true;
        break;
      } else if(type === 'sound') {
        if(this.soundsPlaying[i].key === key) {
          ret = true;
          break;
        }
      }
    }
    return ret;
  };

  isInQueue = (data) =>  {
    let ret = false;
     for(let i=0;i<this.soundQueue.length;i++) {
       if(this.soundQueue[i].type === data.type &&
          this.soundQueue[i].name === data.name &&
          this.soundQueue[i].key === data.key) {
            ret = true;
        }
      } // End for
      return ret;
  };

//
// type - type of sound. 'sprite','sound'
// name - name of the sound or the spriteName if a sprite
// key - (for sprites) the key that stored these sprites
// evt - event to pass back to calling class
  queueSound(type,name,key=null,evt=null) {
    // Is this sound already playing? If so then DON'T queue it
    if(!this.isSoundInList(type,key,name)) {
      // don't queue a sound we have already queued
      if(!this.isInQueue({type:type,key:key,name:name,event:evt})) {
        this.log("queueSound","going to queue sound name="+name);
        this.soundQueue.push({type:type,key:key,name:name,event:evt});
      } else {
        this.log("queueSound","SOUND ALREADY IN QUEUE!");
      }
    } else {
      this.log("queueSound","Sound currently playing so NOT queueing");
    }
  }

  playQueuedSound(data) {
    this.log("playQueuedSound","playing from queue type="+data.type+" name="+data.name+" key="+data.key);
      if(data.type === 'sprite') {
        this.playSpriteWithEvent(data.key,data.name,data.event);
      } else if(data.type === 'sound') {
        this.playSingleSound(data.name);
      }
  }
//
// This is called when a sound is done playing.If there is no sound currently playing
// then if there are sounds queued up it will play the first sound in the queue.
  soundDonePlaying(callerCB,soundId) {

       this.log("soundDonePlaying","Sound Done soundId="+soundId);
       let evt = this.removeSoundFromList(soundId); // Returns event or null
       if(!this.isAnySoundPlaying()) {
         if(this.soundQueue.length > 0) {
           let qsound = this.soundQueue.shift();  // Pull off the first element
           this.soundCurrentlyPlaying = qsound.name;
           setTimeout(this.playQueuedSound.bind(this),1000,qsound);
         } else {
           this.soundCurrentlyPlaying = "";
         }
       }
       callerCB(soundId,evt);
  }

  isSoundCurrentlyPlaying(snd) {
    return (this.soundCurrentlyPlaying === snd);
  }

//
// Be sure and call setDonePlayingCB BEFORE loading the sprite.
  loadSprite(key,file,spriteData,doneCB,preloadFile=true) {
    let localCB = this.soundDonePlaying.bind(this,doneCB);
    this.soundSprites[key] =  new Howl({src:[this.folderBase+file],
                            onend: localCB,
                            sprite: spriteData,
                            preload:preloadFile
                          });;
  }

  setLoop(key,isSprite=false,soundId) {
    if(isSprite) {
      this.soundSprites[key].loop(true,soundId);
    } else {
      this.sounds[key].loop(true);
    }
  }

  stop(key,isSprite=true,sid=-99) {
    if(isSprite) {
      this.soundSprites[key].stop();
    } else {
      this.sounds[key].stop();
    }
    if(sid === -99) {
      console.log("<ERROR-STOP SOUND> No sound ID passed in");
    }
    this.removeSoundFromList(sid); // Returns event or null

  }

  loadSingleSound(key,file,doneCB) {
    let localCB = this.soundDonePlaying.bind(this,doneCB);

    this.sounds[key] = new Howl({src:[this.folderBase+file],
                        onend: localCB });
  }

  setDonePlayingCB(fnc) {
    this.donePlayingCB  = fnc;
  }

// Play a sprite and once complete pass an event key back
// key - Key for the sprite file
// spritename - Name of the sprite to play
// evt - Event ID to pass back to the calling method
// queue - Queue this sound if another is playing.
   playSpriteWithEvent(key,spritename,evt,queue=false) {
      return this.doPlaySprite(key,spritename,queue,evt);
   }

   playSprite(key,spritename,queue=false) {
     return this.doPlaySprite(key,spritename,queue,null); // null event
   }
//
// play a sprite from a loaded sound sprite
// Return ID of sound played or -99 if not played (possibly queued)
  doPlaySprite(key,spriteName,queue=false,evt=null) {
     let sid = -99; // NO sound played as the sound is currently playing
     if(!this.isAnySoundPlaying()) {
         this.soundCurrentlyPlaying = spriteName;
         sid = this.soundSprites[key].play(spriteName);
         this.soundsPlaying.push({id:sid,event:evt,type:'sprite',key:key,sprite:spriteName});
         this.log("playSprite","playing spriteName="+spriteName+" sid="+sid);

     } else if(queue) {
       this.queueSound('sprite',spriteName,key,evt);
     }
     return sid;
  }
//Just immediately play something. We don't care about when it finishes or queueing
// or anything like that. Just play it.
  playSpriteImmediately(key,spritename) {
    this.soundSprites[key].play(spritename);
  }
//
// Play a single preloaded sound
// key - name of the sound
// override - true if we want to play regardless of if anything else is currently playing
// queue - if true then queue the sound if another is playing
  playSingleSound(key,override=false,queue=false) {
    let sid = -99;
    if(override || !this.isAnySoundPlaying()) {
      sid = this.sounds[key].play();
      this.soundCurrentlyPlaying = key;
      this.soundsPlaying.push({id:sid,event:null,type:'sound',key:key});
      this.log("playSingleSound","playing single sound="+key+" sid="+sid);
    } else if(queue) {
      this.queueSound('sound',key);
    }
    return sid;
  }
//
// Currently cannot queue this type of sound
  playSound(file) {
    let sid = -99;
//    this.log("playSound","file="+file);
    if(!this.isSoundCurrentlyPlaying(file)) {
      this.state.soundFile = file;
      let sound = new Howl({src:[this.folderBase+file],
                          onend: this.soundDonePlaying });
      sid = sound.play();
      this.soundsPlaying.push({id:sid,type:'file'});

    }
//    sound.on('end',  function() { this.soundDonePlaying() } );
    return sid;
  }
//
// works like loading a sound but sets it to loop and fadein/out
  loopSound(file,volume=null,playIt=true) {
    let sound = null;
    if(!(file in this.sounds)) {
      let soundData = {src:[this.folderBase+file],loop:true};
      if(volume !== null) {
        soundData.volume = volume;
      }
      sound = new Howl(soundData);
      this.sounds[file] = sound;
      if(playIt) {
         sound.on('load',()=> {
           this.fadeIn(sound,volume,2000);
         });
      }
    } else {
      sound = this.sounds[file];
      if(playIt) {
        this.fadeIn(sound,volume,2000);
      }
    }
    return sound;
  }

  fadeOut = (sound,from,over) => {
     //  sound.once('fade',function() {
     //    sound.stop();
     //    console.log('(fadeOut) Stopping inside the once function');
     //  });
     // sound.fade(from,0.0,over);
     sound.stop();
     // sound.unload();
     // sound.volume(0);
  };

  fadeIn = (sound,vol,over) => {
    sound.fade(0.0,vol,over);
    sound.play();
  };

}

export default SoundManager;
