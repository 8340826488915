
export const heroes = ['Princess Jasmine','Aladdin',"Belle",
                       'Hercules','Woody','Peter Pan','Wall-E',
                        'Jack-Jack','Mickey Mouse','Buzz Lightyear',
                       'Jack Sparrow','Maui','Moana','Merida','Stitch',
                       'Simba','Pumbaa','Goofy','Minnie Mouse','Ariel',
                     'Jack Skellington','Elsa','Olaf','Mirabel'];
export const villains = ['Jafar','Iago','Ursula','Kaa','Maleficent',
'Scar','Cruella de Vil','Queen of Hearts','Chernabog','Captain Hook',
 'Hades','Dr. Facilier','Syndrome','King Candy','Gaston','Randall Boggs',
 'Oogie Boogie','Evil Queen','Madame Medusa','Bowler Hat Guy','Master Control Program'];

export const heroIcons = ['jasmine_icon.jpg','aladdin_icon.jpg','belle_icon.jpg',
                          'hercules_icon.jpg','woody_icon.jpg','peter_pan_icon.jpg','walle_icon.jpg',
                        'jack_jack_icon.jpg','mickey_icon.jpg','buzz_icon.jpg',
                      'jack_sparrow_icon.jpg','maui_icon.jpg','moana_icon.jpg','merida_icon.jpg','stitch_icon.jpg',
                      'simba_icon.jpg','pumbaa_icon.jpg','goofy_icon.jpg','minnie_icon.jpg','ariel_icon.jpg',
                      'jack_skel_icon.jpg','elsa_icon.jpg','olaf_icon.jpg','mirabel_icon.jpg'];

export const villainIcons= ['jafar_icon.jpg','iago_icon.jpg','ursula_icon.jpg','kaa_icon.jpg','maleficent_icon.jpg',
'scar_icon.jpg','cruella_devil_icon.jpg','queen_hearts_icon.jpg','chernabog_icon.jpg','captain_hook_icon.jpg',
'hades_icon.jpg','dr_faciler_icon.jpg','syndrome_icon.jpg','king_candy_icon.jpg','gaston_icon.jpg','randall_boggs_icon.jpg',
 'oogie_boogie_icon.jpg','evil_queen_icon.jpg','madame_medusa_icon.jpg','bowler_hat_icon.jpg','master_control_icon.jpg'];
