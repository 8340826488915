import {useEffect,useState} from "react";
import "./gameCard.css";
import * as Actions from '../../constants/actions.js';
import {withFirebase} from '../Firebase';

function GameCard(props) {

  const theCard = props.card;
  const completeTurnFunc = props.completeTurnFunc;
  const myFirebase = props.firebase;
  const gameStateManager = props.gsm;
  const soundManager = props.soundManager;
  const gameController = props.gameController;
  var spellHasBeenCast = false;
  var magicHasBeenPerformed = false;
  var magicButtonTitle = (theCard.hasOwnProperty('actionTitle')) ? theCard.actionTitle:"Perform Magic";
  var spellButtonTitle = (theCard.hasOwnProperty('actionTitle')) ? theCard.actionTitle:"Cast Spell";

  const [optionChosenText, setOptionChosenText] = useState("");

  const [showSpellButton,setShowSpellButton] = useState(false);
  const [showMagicButton,setShowMagicButton] = useState(false);

  useEffect(() => {
//    console.log("----------useEffect called");
     if(theCard.action === Actions.CAST_SPELL && !spellHasBeenCast) {
       setShowSpellButton(true);
     }
     if(theCard.action === Actions.PERFORM_MAGIC && !magicHasBeenPerformed) {
       setShowMagicButton(true);
     }
     setOptionChosenText("");
  },[theCard,spellHasBeenCast,magicHasBeenPerformed])

 function getCardText () {
  // console.log("(getCardText)theCard.actionType="+theCard.actionType);
   return (theCard.text);
 }

 function getCardHeader () {
   var ret = "";
   if(theCard.cardType === Actions.SWAP_BODIES_SPELL && gameStateManager.isThisPlayersTurn()) {
     ret = <div className="spellBackfire">Ooop! You're spell kinda backfired and bounced onto you!</div>;
   }
   return (ret);
 }

 function getCardFooter () {
   var ret = "";
   if(theCard.cardType === Actions.HERO_VILLAIN_SWAP ) {
     if(gameStateManager.isHero()) {
        ret = <div>Being the HERO in this situation, you are in charge. <br/> You may take the Villain's gift if they have one.<br/> If you now have 2 gifts you must either place your original gift back in the main pile or if the person on either side of you does not have a gift you can give it to them. </div>;
     } else {
       ret = <div>Being the VILLAIN in this situation and having been rendered weak for the moment, just sit back and submit to whatever is requested of you.</div>;
     }
   } else if(theCard.cardType === Actions.VILLAIN_HERO_SWAP) {
     if(gameStateManager.isHero()) {
        ret = <div>You have been incapacitated by that villainous burp. <br/> You'll have to comply with whatever evil deed is about to happen.<br/>That burp may even have affected those on either side of you.</div>;
     } else {
       ret = <div>Being the VILLAIN in this situation you are in charge.<br/>If you have no gift you may take the Hero's or take from the main pile.<br/> If you already have a gift you may trade with the Hero.<br/> Or you can just hang on to your gift and direct the hero to trade with the person on either side of them. </div>;
     }
   }
   return (ret);
 }

 function completeTurn() {
   setShowSpellButton(false); // These calls don't seem to do anything and I don't know why
   setShowMagicButton(false);
   // If we got here as a result of an action card (magic or something) then we don't want to
   // complete the turn becuase it's not really this persons turn. So just hide everything.
   // UNLESS this person cast a spell and it backfired and landed on them. So we need to
   // check for that case.
   var nextPlayer = true;
   if(theCard.hasOwnProperty('isActionCard') && theCard.isActionCard) {
     if(!gameStateManager.isThisPlayersTurn()) {
        nextPlayer = false;
     }
   }
   completeTurnFunc(nextPlayer);
 }
//
// Perform magic based on the current card being played
 function performMagic() {
   soundManager.playSprite("gamesounds","performMagic");
   magicHasBeenPerformed=true;
   setShowMagicButton(false);

   if(theCard.actionType === Actions.PICK_OR_TAKE_ACTION || theCard.actionType === Actions.OPEN_GIFT
       || theCard.actionType === Actions.TAKE_OR_OPEN_GIFT) {
      var player = getRandomInt(gameStateManager.getNumberOfPlayers(),gameStateManager.getPlayerNumber());
//     console.log("magic for player="+player+" current player ="+gameStateManager.getPlayerNumber());
      myFirebase.performMagic(theCard.actionType,player);
   } else if(theCard.actionType === Actions.HERO_VILLAIN_SWAP ) {
     // Find a hero and a villain and use those players to perform the magic.
     var players = pickHeroAndVillain();
     var player1,player2;
     player1 = players.hero;
     player2 = players.villain;
     myFirebase.performMultiPlayerMagic(theCard.actionType,player1,player2); // Player casting spell is first

   } else if(theCard.actionType === Actions.VILLAIN_TO_HERO) {
     // Choose the next player that is a villian to change characters. Doing it this way rather than random
     // will give most everyone the chance to have their character changed

     var playerToChange = gameController.findFirstVillain()

     myFirebase.performMagic(theCard.actionType,playerToChange);

   } else if(theCard.actionType === Actions.OPTION_A_OR_B) {
       var player = getRandomInt(gameStateManager.getNumberOfPlayers(),gameStateManager.getPlayerNumber());

        var val = getRandomInt(100);
        var magicType = Actions.RELINQUISH_GIFT_SPELL;
        if(val > 66) {
          magicType = Actions.PICK_OR_TAKE_ACTION;
        } else if(val > 33 && val < 66) {
          magicType = Actions.OPEN_GIFT
        }
        myFirebase.performMagic(magicType,player);

   } else if(theCard.actionType === Actions.BECOME_VILLAIN) {
     var playerToChange = gameStateManager.getPlayerNumber();
     myFirebase.performMagic(Actions.HERO_TO_VILLAIN,playerToChange);

   }
 }

 function performOptionB() {

     magicHasBeenPerformed=true;
     setShowMagicButton(false);

     var val = getRandomInt(100);
     if(val > 66) {
        setOptionChosenText("Well you lucked out! Pick a gift from the pile if you don't have one. Or you may choose to trade with anyone if you have a gift, open or otherwise. If you end up with a wrapped gift, don't open it!");
     } else if(val > 33 && val < 66) {
        setOptionChosenText("Well that wasn't very lucky. Fortunately it wasn't very unlucky either. In fact nothing at all happened. Have a drink!");
     } else {
       setOptionChosenText("Well that wasn't very lucky. If you have a gift offer it to the person on your left. They may take it but will have to put their gift back in the pile if they already have one.");
     }

 }

 function getOptionChosen() {
   var ret = "";
   ret = optionChosenText;
   return (ret);
 }

  function pickHeroAndVillain () {
    var hero = gameController.choosePlayerByType("hero");
    var villain = gameController.choosePlayerByType("villain");
    return {hero:hero,villain:villain};
  }




 function getRandomInt(max,notThis=-1,alsoNotThis=-1) {
    var val = Math.floor(Math.random() * max) + 1;

    if(notThis > 0 && max > 1) {  // to avoid endless loop
       while(val === notThis) {
         val = Math.floor(Math.random() * max) + 1;
       }
     }
     // now check for a possible second race condition
     if(alsoNotThis > 0) {
       if(notThis > 0 && max >= 3) { // we need at least 3 in order to pick in this case
         while(val === notThis || val === alsoNotThis) {
           val = Math.floor(Math.random() * max) + 1;
         }
       }
     }
    return val;
 }

 function castSpell() {
   var player2;
//   console.log("cast spell");
   spellHasBeenCast = true;
   setShowSpellButton(false);
   playDarkMagicSound();
   var player = getRandomInt(gameStateManager.getNumberOfPlayers(),gameStateManager.getPlayerNumber());
//   console.log("spell for player="+player+" current player ="+gameStateManager.getPlayerNumber());
//   console.log("Spell to cast="+theCard.actionType);
// For the moment perform magic is really the same as casting a spell, the end result is what differs
   if(theCard.actionType === Actions.MULTI_TRADE_SPELL) {
     player2 = getRandomInt(gameStateManager.getNumberOfPlayers(),gameStateManager.getPlayerNumber(),player);
     myFirebase.performMultiPlayerMagic(theCard.actionType,player,player2);
   } else if(theCard.actionType === Actions.SWAP_BODIES_SPELL) {
     player2=gameStateManager.getPlayerNumber(); // This player
     myFirebase.performMultiPlayerMagic(theCard.actionType,player2,player); // Player casting spell is first
   } else if(theCard.actionType === Actions.VILLAIN_HERO_SWAP) {
     var players = pickHeroAndVillain();
     var player1;
     player2 = players.hero;
     player1 = players.villain;
     myFirebase.performMultiPlayerMagic(theCard.actionType,player1,player2); // Player casting spell is first
   } else if(theCard.actionType === Actions.HERO_TO_VILLAIN) {
     var playerToChange = gameStateManager.getPlayerNumber()+1;
     var nump = gameStateManager.getNumberOfPlayers();
     if(playerToChange > nump) {
       playerToChange = 1;
     }
     myFirebase.performMagic(theCard.actionType,playerToChange);
   } else if(theCard.actionType === Actions.BECOME_HERO) {
     var playerToChange = gameStateManager.getPlayerNumber();
     myFirebase.performMagic(Actions.VILLAIN_TO_HERO,playerToChange);

   }
   else {
      myFirebase.performMagic(theCard.actionType,player);
   }
 }

 function playDarkMagicSound() {
   var snd = getRandomInt(3);
   var nm = "darkMagic"+snd;
   soundManager.playSprite("gamesounds",nm); // Todo: random 3 sounds
 }

 function displayPerformMagic() {
   var ret = "none";

   if(showMagicButton && !magicHasBeenPerformed) {
     ret = "inline";
   }
   return ret;
 }

 function displayCastSpell() {
   var ret = "none";
   // if(theCard.action === Actions.CAST_SPELL) {
   //   ret = "inline";
   // }
   if(showSpellButton && !spellHasBeenCast) {
     ret = "inline";
   }
   return ret;
 }

 function displayOptionBButton() {
   var ret = "none";
   if(showMagicButton && !magicHasBeenPerformed && theCard.actionType === Actions.OPTION_A_OR_B) {
     ret = "inline";
   }
   return ret;
 }

 function shouldDisplayCompleteTurn() {
     var ret = "inline";
     if(showSpellButton || showMagicButton) {
       ret = "none"
     }
     return ret;
 }

 function getOptionChosenText() {
   var ret = "";
   if(optionChosenText !== "") {
     ret = <div className="optionsChosenText">{optionChosenText}</div>;
   }
   return(ret);
 }

 return (
   <div>
     <div className="gameCard">
      {getCardHeader()}
      <br/>
      {getCardText()}
      {getOptionChosenText()}
      {getCardFooter()}

     </div>
     <div style={{display:displayPerformMagic()}}>
        <button className="basicButton performMagicButton" value="Peform Magic" onClick={performMagic}>{magicButtonTitle}</button>
     </div>

     <div style={{display:displayCastSpell()}}>
        <button className="basicButton castSpellButton" value="Cast Spell" onClick={castSpell}>{spellButtonTitle}</button>
     </div>

     <div style={{display:displayOptionBButton()}}>
        <button className="basicButton performMagicButton" value="Option B" onClick={performOptionB}>Option B</button>
     </div>



     <button className="completeTurnButton" style={{display:shouldDisplayCompleteTurn()}} value="Complete Turn" onClick={completeTurn}>Complete Turn</button>
   </div>
 );

}

export default withFirebase(GameCard);
