
import * as Actions from './actions.js';

export const HeroCards = [
  {action:Actions.PERFORM_MAGIC,actionType:Actions.BECOME_VILLAIN,
  text:<div>Your neighbor left a wonderful apple pie on your porch as a thank you for saving the village.<br/>It isn't until your second delicious piece that you remember you don't actually have a neighbor.<br/>In fact you havn't saved a village in years!<br/>Perform some magic on the pie to see where it came from.</div>,actionTitle:"Investigate Further"},
  {action:Actions.PERFORM_MAGIC,actionType:Actions.OPTION_A_OR_B,actionTitle:"Option A",
   text:<div>Due to a clerical error something somewhere at sometime happened and now you've been presented with 2 options.<br/>All you've been told is that Option A affects someone else while Option B affects you. <br/>Either option may be good or may be not so good.</div>},
  {action:Actions.PERFORM_MAGIC,actionType:Actions.VILLAIN_TO_HERO,actionTitle:'Make Change Happen',
  text:<div>When it's time to change, you've got to rearrange<br/>Who you are and what you're gonna do.<br/>Sha na na na na na na na<br/>Sha na na na na</div>},
{text:"Trade your gift with another hero",action:Actions.NO_ACTION},
{text:"Trade your gift with a villain",action:Actions.NO_ACTION},
{text:"A hero's work is never done. Herocially pick a gift and open it.",action:Actions.NO_ACTION},
{text:"As a hero you right any wrong...well you try.  If the person to your immediate right doesn't have a gift, have them pick one and open it.",
     action:Actions.NO_ACTION},
{text:<div>Time for another good deed. Wave your magical phone, chant<br/> <b>TO THEE A GIFT SHALL COME</b> <br/>and then press the Perform Magic button</div>,
       action:Actions.PERFORM_MAGIC,actionType:Actions.PICK_OR_TAKE_ACTION},
{text:<div><b>You found a magic rock(don't worry, it's a soft rock)!</b><br/>I know, it doesn't sound too exciting. But oh boy it sure is!<br/>Toss the rock into the air and see where it lands!</div>,
      action:Actions.PERFORM_MAGIC,actionType:Actions.PICK_OR_TAKE_ACTION,actionTitle:'Toss Rock in the Air'},
{text:<div>Be heroic and open your gift with a grand <b>TA DAAAA</b>.<br/>If you don't have a gift to open, be brave and have a drink instead.</div>,action:Actions.NO_ACTION},
{text:<div>While chasing after the ice cream truck to give back the extra nickle in change they gave you<br/>you trip on a magic rock, fall on your butt, and TOOT a little toot.<br/>But in this land, even farts are magical.</div>,
   action:Actions.PERFORM_MAGIC, actionType:Actions.HERO_VILLAIN_SWAP,actionTitle:'Toot a Magical Fart'},
   {text:<div><b>Congratulations!</b><br/>You have found the secret hiding place of the rare <i>amulet of impediment</i>. <br/>You may use the amulet only once to block an attempt to take your gift. Once used, return the amulet from whence it came.<br/><b>BUT REMEMBER!</b><br/>You're a hero, so if another hero discovers its hiding place you must give it willingly to them(though feel free to do so with snarky good will).<br/><i>If you find the amulet again you may use it again.</i></div>,action:Actions.NO_ACTION},
{action:Actions.NO_ACTION,
 text:<div>Stop doing heroic deeds for a few minutes and just take a gift and open it.<br/>Alternately you can open a restaurant selling Sliders and Wings and call it <i>Slings</i>.<br/>Your choice!</div>},
 {action:Actions.PERFORM_MAGIC,actionType:Actions.OPEN_GIFT,actionTitle:'Shoo the Squirrel Away',
   text:<div>A squirrel just jumped up on the ledge and started talking to you.<br/>Apparently it's true that heroes can talk to animals.<br/>But it's a bit creepy so use some magic to shoo the critter away.  </div>},
  {text:"Trade your gift with anyone",action:Actions.NO_ACTION},
  {text:"If you don't have a gift, take one from the pile but don't open it, because you have other heroic things to do.",action:Actions.NO_ACTION},
  {text:<div>One lucky person is going to be the recpient of something magical...maybe. You'll do your best. <br/>Just press the magical button when you're ready.</div>,
     action:Actions.PERFORM_MAGIC,actionType:Actions.TAKE_OR_OPEN_GIFT,actionTitle:'Perform Random Magic'},
     {text:"Sit back, relax, take a sip of your cocktail and bask in the glow of doing nothing.",action:Actions.NO_ACTION},
     {text:<div><i>Heigh-Ho...Heigh-Ho</i>...Off to the bathroom you go!<br/><i>Heroically, of course! Or is that Hero Ickily?</i></div>,action:Actions.NO_ACTION}

];

export const VillainCards = [
  {action:Actions.CAST_SPELL,actionType:Actions.BECOME_HERO,
  text:<div>The candy bar you stole from that small child isn't sitting right.<br/>Cast a spell to ease your tummy.</div>,actionTitle:"Take a Tums"},
  {text:<div>A sudden surge of power rushes through you, or is that just the questionable sushi you shared with Ariel earlier?<br/>Well in any case, cast a spell and see if that helps.</div>,
    action:Actions.CAST_SPELL,actionType:Actions.MULTI_TRADE_SPELL,actionTitle:'Purge the Sushi'},
{text:"Trade your gift with another villain",action:Actions.NO_ACTION},
{text:"Trade your gift with a hero",action:Actions.NO_ACTION},
{text:<div>While chugging an Evil IPA from the local brewery you suddenly feel like you need to burp.<br/>Doing so will likely cause a sudden release of evil bubbles into the air and who knows what will happen.</div>,
          action:Actions.CAST_SPELL,actionType:Actions.VILLAIN_HERO_SWAP,actionTitle:'Release a Burp'},
{text:<div>Being a villain can be exhausting.<br/>If you don't have a gift, command someone to pick one from the pile for you. <br/>Take it but don't open it.</div>,
     action:Actions.NO_ACTION},
{text:"If you do not have a gift take anyone's gift or take from the pile and open it.",action:Actions.NO_ACTION},
{text:<div>A villain's work is never done. Sometimes you just want to cast random spells and you don't even know what will happen.<br/> Chant some non-sensical words and then press the <b>Cast Spell</b> button.</div>,
      action:Actions.CAST_SPELL,actionType:Actions.TRADE_SPELL},
{text:<div>All these other people can be so tedious when you have villainous work to be done.<br/>If you knew they wouldn't spill it you'd cast a spell to have someone lift your drink for you.<br/>But alas, instead it's time to cast a random spell.</div>,
      action:Actions.CAST_SPELL,actionType:Actions.RELINQUISH_GIFT_SPELL},
{action:Actions.CAST_SPELL,actionType:Actions.SWAP_BODIES_SPELL,
     text:<div key='swap'><div className='spellCardheader'>Time for a random act of evil.</div>But beware, playing with dark magic can be dangerous.<br/>Chant <i>flippity floppity snarf!</i> and then press the <b>Cast Spell</b> button.</div>},
{text:<div>Be villainous and open your gift with an evil laugh.<br/>If you don't have a gift to open, take a drink and laugh evily...is that word?<br/>Raise your hand and decree <i>evily</i> a new word.</div>,
     action:Actions.NO_ACTION},
{text:<div>Is it time to eat yet? Villains have a large appetite....for EVIL.<br/> If you don't have a gift, take one from the main pile and open it.<br/>I'm not sure if the <i>Pompous Office of Definitive Living Evil</i> (or POODLE as it's known) <br/>will recognize that as an evil deed, but what the heck!</div>,
     action:Actions.NO_ACTION},
 {text:<div>If your gift is unopened, trade it with an unopened one from someone else.<br/>Feel free to laugh an evil laugh while you do it.</div>,
     action:Actions.NO_ACTION},
 {action:Actions.CAST_SPELL,actionType:Actions.HERO_TO_VILLAIN,actionTitle:'Make New Friends',
 text:<div>As a villain you're finding it hard to find people to have cocktails, finger sandwiches, and take over kingdoms with.<br/>So you decide to make some new friends.<br/>No, literally you're going to <b>make</b> some new friends.<br/>Have fun storming the castle!</div>},
 {action:Actions.PERFORM_MAGIC,actionType:Actions.OPEN_GIFT,actionTitle:'Shoo the Raven Away',
   text:<div>A raven just landed on your shoulder and squawked at you.<br/>Apparently as an evil doer this is something you should like.<br/>But as a child you were pooped on by a bunch of pigeons so you'd rather not have this big thing on your being.</div>},
   {text:"Trade your gift with anyone",action:Actions.NO_ACTION},
   {text:"If you don't have a gift, take one from the pile, open it, and then do a slightly evil laugh.",action:Actions.NO_ACTION},
   {text:<div>Command someone to get you a tasty appetizer, STAT!<br/> When they bring it, tell them you changed your mind and don't want it and then laugh evilly.</div>,action:Actions.NO_ACTION},
   {text:<div>Quick, take a picture of someone and then laugh evilly.<br/> Claim that you shall add googily eyes and then post it to <i>the Instagrams</i> just for fun(evil laugh is optional).</div>,action:Actions.NO_ACTION},
   {text:<div><b>AT LONG LAST!</b><br/>You have found the elusive <i>stone of occlusion</i>.<br/>You may use the stone only once to block an attempt to take your gift. Once used, return the stone from whence it came.<br/><b>BUT BEWARE!</b><br/>If another villain discovers its hiding place they may take it from you with an <i>evily</i> laugh. Have we declared <i>evily</i> yet? No? Well we'll get to that.<br/><i>If you find the stone again you may use it again.</i></div>,action:Actions.NO_ACTION},
   {text:<div>As a villain, it is your job to be villainous.<br/>That goes without saying even though I just said it.<br/>For the next 26 seconds you may taunt any heroes using phrases that contain the word <i>kerfuffle</i>, <i>discombobulate</i>, or <i>ragamuffin</i>. <br/>Your time starts now. GO!</div>,action:Actions.NO_ACTION}

];

// These are cards played on another player as a result of spells or magic
export const ActionCards = [
  {cardType:Actions.PICK_OR_TAKE_ACTION,text:<div><b>Some sort of magic has landed on you.</b><br/>If you don't have a gift, take one from the pile and unwrap it.<br/>If you have a gift open it.<br/>If you have an open gift, you MAY trade with anyone.<br/><br/>You might want to keep an eye out because next time it might not be something as nice as magic that lands on you. Pigeons! Just saying.</div>,
   action:Actions.NO_ACTION,isActionCard:true},
  {cardType:Actions.TRADE_SPELL,action:Actions.NO_ACTION,isActionCard:true,
      text:<div><div className='spellCardHeader'>An evil force is causing you to do things you may not want to do</div> <b>BWA HA HA</b><br/>If you have a gift, open or not, you must trade it with someone, open or not.<br/>If you end up with an unopened gift, don't open it. Because you know, this is evil.<br/><i>(insert evil laugh here)</i></div>},
  {cardType:Actions.MULTI_TRADE_SPELL,isActionCard:true,action:Actions.NO_ACTION,
  text:<div><div className='spellCardHeader'>A Spell Has Been Cast on You!</div>Trade your gift with the other person this spell was cast upon.<br/>If you don't have a gift then you just take the other person's.<br/><b>Bwa ha ha ha ha cough ack!</b></div>},
  {cardType:Actions.RELINQUISH_GIFT_SPELL,action:Actions.NO_ACTION,isActionCard:true,
   text:<div>Oh what evil hath appeared this time?<br/>Oh, it's just you.<br/>You have the uncontrollable urge to put your gift back into the main pile, so you do.<br/><b>Have a sip of your drink to get over it!</b></div>
 },
  {cardType:Actions.SWAP_BODIES_SPELL,action:Actions.NO_ACTION,isActionCard:true,
   text:<div>A spell has been cast upon you. If you have a gift in your possesion then give it to the other person affected by this spell.<br/>Oh yeah, and you've just swapped characters with that person as well.<br/>No really! Check your character name up there.</div>},
  {cardType:Actions.HERO_VILLAIN_SWAP,action:Actions.NO_ACTION,isActionCard:true,
  text:<div>Well this is interesting. Some magic has landed upon both a Hero and a Villain.<br/></div>},
  {cardType:Actions.VILLAIN_HERO_SWAP,action:Actions.NO_ACTION,isActionCard:true,
   text:<div>Well that burp flew through the air and landed on both a Villain and a Hero<br/></div>},
  {cardType:Actions.OPEN_GIFT,action:Actions.NO_ACTION,isActionCard:true,
   text:<div>As you watch the animal hurry away you have the sudden urge to unwrap your gift (assuming of course you have one).<br/>So unwrap it if ya got one.</div>},
   {cardType:Actions.VILLAIN_TO_HERO,action:Actions.NO_ACTION,isActionCard:true,
    text:<div>You feel a sudden urge to sing an old Brady Bunch song about Changes.<br/><b>*** POOF ***</b><br/>You've changed from a villain to a hero.<br/>Announce your new character to everyone and rejoice!</div>},
  {cardType:Actions.HERO_TO_VILLAIN,action:Actions.NO_ACTION,isActionCard:true,
     text:<div>You feel a sudden urge to kidnap puppies and take over a Kingdom or two<i>(kinda like Elon Musk)</i>.<br/><b>*** POOF ***</b><br/>You've changed from a hero to a villain.<br/>Announce your new character to everyone and laugh an evil laugh!</div>},
  {cardType:Actions.TAKE_OR_OPEN_GIFT,action:Actions.NO_ACTION,isActionCard:true,
     text:<div>Magic has been bestowed upon thee! <br/>If you don't have a gift, take one from the pile(but don't open it). If you have an unopened gift, open it!<br/>If you have a drink, take a sip and enjoy!<br/>If you have some Chirstmas cheer, buy Scott a drink for putting days of work into this game! (Just kidding)<br/>And finally, if you're happy and you know it clap your hands!</div>},
     {text:<div></div>,action:Actions.NO_ACTION}

];
