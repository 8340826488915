import {initializeApp,getApp} from 'firebase/app';
import {getDatabase,onValue, ref as fireRef,off,update,get} from 'firebase/database';
import {Actions} from '../../constants';

import GameUtils from './GameUtils';

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  // const bayouFirebaseConfig = {
  //   apiKey: "AIzaSyAZ1fqkg7wGdxnhAUvXvdUkR3Sfpbu9ep0",
  //   authDomain: "thebayou-14006.firebaseapp.com",
  //   projectId: "thebayou-14006",
  //   storageBucket: "thebayou-14006.appspot.com",
  //   messagingSenderId: "196773665626",
  //   appId: "1:196773665626:web:f65e9588fd896b8b6f3163",
  //   measurementId: "G-6R1972S9X4"
  // };

  var firebaseConfigEntrapmentOnline = {
    apiKey: "AIzaSyC3viHMHfTJgo7flT0xvSybEz-DkvinhiA",
    authDomain: "entrapmentonline.firebaseapp.com",
    databaseURL: "https://entrapmentonline.firebaseio.com",
    projectId: "entrapmentonline",
    storageBucket: "entrapmentonline.appspot.com",
    messagingSenderId: "452208166614",
    appId: "1:452208166614:web:d3bc0437d26d694698ab77"
  };

  // Test user: testuser@thebayou.com/testuser

  // IMPORTANT - Be sure and set the proper value for USE_POLLING below. It should be
  // false be default, set to TRUE if you want to use the POLLING vs DATABASE PUSH
  // Default(false) method is a PUSH from the firebase database but that doesn't seem to work well with
  // Apple or random networks. Set this to TRUE to use the POLLING version instead.
  const USE_POLLING = false;

  class MyFirebase {
     constructor() {
       console.log("(Firebase - CONSTRUCTOR)");
       this.MOCK_LOCAL = false; // Set to FALSE to actually call firebase

	     initializeApp(firebaseConfigEntrapmentOnline);
       this.app = getApp();
//	   this.auth = app.auth();
	     this.database = getDatabase();
       this.databaseRoot = "/villiansgame";
       this.gameDataRef = null;
       this.gameActionRef = null;
       this.listenGameDataFunction  = null;
       this.listenGameActionFunction  = null;

       this.gameDataChangedFunc = null;
       this.gameActionChangedFunc = null;
       // this.auth = getAuth();
       // this.currentUsername = null;
        this.gameUtils = new GameUtils(this);

        this.gameStateManager = null;

	 }

//
// GSM is now used for polling.
   setGameStateManager(gsm) {
     this.gameStateManager = gsm;
   }

//
// Call this to set the function to call when game data changes
   setGameDataChangedFunc = (func)=> {
      this.gameDataChangedFunc = func;
   };
   setGameActionChangedFunc = (func)=> {
      this.gameActionChangedFunc = func;
   };
//
// Get a list of all the players that are playing
   getCurrentUsers = (funcToCall) => {
     var theRef = fireRef(getDatabase(),this.databaseRoot+"/players");
    get(theRef).then((snapshot)=> {
       funcToCall(snapshot);
     });
   };

   //
   // Get a list of all the players that are playing
  getCurrentUsersAsProm = () => {
      var theRef = fireRef(getDatabase(),this.databaseRoot+"/players");
      var prom = get(theRef);
      return prom;
      };


// Clear all the data in the current players so we can start over.
// Entry point is clearAllUserData below, not this.
   doClearAllUserData = (snapshot) => {
     var theRef = fireRef(getDatabase(),this.databaseRoot+"/players");
     var data={};
     snapshot.forEach(function(childsnapshot) {
        data[childsnapshot.key] = {id:0,character:"NONE",type:"NONE"};
     });
     update(theRef,data).then(()=> {
         console.log("(doClearAllUserData) User data updated");
         // TODO: Setup a listener to the gameData
     }).catch((error) => {
       console.log("(doClearAllUserData) Unable to update-Error: "+error);

     });
  //      console.log("(doClearAllUserData) data="+data.scott);
   };

   clearAllUserData = () => {
     this.getCurrentUsers(this.doClearAllUserData);

   };
//
// Set active player to 0 and set cards to 0
   resetGame = () => {
     var theRef = fireRef(getDatabase(),this.databaseRoot+"/gameData");
     update (theRef,{
              playerNum: 0,
              heroCardNum: 0,
              villianCardNum: 0
            }).then(()=> {
                this.clearActionData();
                // TODO: Setup a listener to the gameData
            }).catch((error) => {
              console.log("(resetGame) Unable to reset-Error: "+error);

            });

   };

   clearActionData = () => {
     var theRef = fireRef(getDatabase(),this.databaseRoot+"/gameAction");
     update (theRef,{
              playerNum: 0,
              playerNum2: 0,
              actionType: 0
            }).then(()=> {
                console.log("(resetGame) Game reset");
                this.clearMessages();
                // TODO: Setup a listener to the gameData
            }).catch((error) => {
              console.log("(resetGame) Unable to reset-Error: "+error);

            });
   };


// A player's turn is over so update the cards and next player
   playerTurnIsComplete = (whoseTurn,numPlayers) => {
     var theRef = fireRef(getDatabase(),this.databaseRoot+"/gameData");
     var num = whoseTurn + 1;
     if(num > numPlayers) {
       num=1;
     }
     var prom = update (theRef,{
              playerNum: num,
              heroCardNum: 0,
              villianCardNum: 0
            });
     return prom;
   };

   callPlayerTurnIsComplete = () => {
      this.playerTurnIsComplete(this.gameStateManager.getWhichPlayersTurn(),this.gameStateManager.getNumberOfPlayers());
   };
//;
// DOES NOT WORK FOR SOME REASON
   deleteUnnamed = () => {
     var theRef = fireRef(getDatabase(),this.databaseRoot+"/players");
     get(theRef).then(function(snapshot) {
         snapshot.forEach(function(child) {
           if(child.key === 'UNNAMED') {
             console.log("need to remove this one "+child.ref);
          //   child.remove();
           }
           // child.ref.remove();
           // console.log("UNNAMED removed");
         });
     });

   };
//
// Get the next player to play
   getNextPlayer = () => {
     var theRef = fireRef(getDatabase(),"/villiansgame/gameData");
     var prom = get(theRef)
     return prom;
   };
//
// charType = hero or villian
// changeId - defaults to true. set to false only when changing a character in the middle of the game
//           in which case we don't want to change the order which the ID is used for
   updateUserCharacter = (username,character,charType,changeId=true) => {
     var prom = null;
     if(this.MOCK_LOCAL) {
       console.log("(MOCK updateUserCharacter) user="+username+" char="+character);
       return;
     }
     if(username !== "UNNAMED") {
       var theData = {character:character,type:charType};
       var theId = Date.now();
       if(changeId) {
         theData['id'] = theId;
         console.log("(updateUserCharacter) Changing the ID");
       }
       var theRef = fireRef(getDatabase(),this.databaseRoot+"/players/"+username);
       prom = update (theRef,theData).then(()=> {
                  console.log("(updateUserCharacter) User Character updated");
                  // TODO: Setup a listener to the gameData
              }).catch((error) => {
                console.log("(updateUserCharacter) Unable to update-Error: "+error);

              });
      }
      return prom;
   };
//
// A character has swapped so do an action that tells all the players to update the info
   tellPlayersOfCharacterSwap = (playerNum,char,charType) => {
      this.gameUtils.doPerformDataMagic(Actions.CHAR_SWAP,playerNum,{name:char,type:charType});
   };


//
// Reset which player is playing and which card we are on in the database
   startGame = () => {
     var prom;
     if(this.MOCK_LOCAL) {
       prom = new Promise((resolve,reject)=>
       {
         setTimeout(()=> {
           resolve("mockStartGame");
         },2000);
       });
     } else {
       var theRef = fireRef(getDatabase(),"/villiansgame/gameData");
       prom = update (theRef,{
           villianCardNum:0,
           heroCardNum:0,
           playerNum:1
       });
     }

     return prom;
   };
//
// Draw a card.
   performMagic=(type,player) => {
     this.gameUtils.doPerformMagic(type,player);
   };

   performMultiPlayerMagic=(type,player1,player2)=> {
     this.gameUtils.doPerformMagic(type,player1,player2);
   };

// Setup the various listeners
  setupGameListeners = () => {
    if(!USE_POLLING) {
      this.setupGameDataListener();
      this.setupGameActionListener();
    } else {
      this.setupGameAndActionPolling();
    }
  };

 //
 // Listen for changes in the gameData and see if the active player is this user or not.
 // If we don't have a list of the current users then we first get all the users.
   setupGameDataListener = () => {

     if(this.MOCK_LOCAL) {
       console.log("(MOCK) setupGameDataListener");
     } else {
         this.gameDataRef = fireRef(getDatabase(),"/villiansgame/gameData");
         // For some reason the onValue gets triggered twice if I don't call OFF here even if I have
         // never called "on". Don't know why that is happening. doesn't make sense.
         off(this.gameDataRef);

  //       if(this.listenGameDataFunction != null) {
           this.listenGameDataFunction = onValue(this.gameDataRef,  (snapshot) => {
             // console.log("GAME DATA HAS CHANGED");
                const data = snapshot.val();
                // console.log("data="+data);
                this.gameDataChangedFunc(snapshot); // This is a function passed in to here
            });
          // } else {
          //   console.log("(setupGameDataListener) Listen function already set. Not setting again");
          // }
      }


   };

   //
   // Listen for changes in the gameData and see if the active player is this user or not.
   // If we don't have a list of the current users then we first get all the users.
     setupGameActionListener = () => {

       if(this.MOCK_LOCAL) {
         console.log("(MOCK) setupGameActionListener");
       } else {
           this.gameActionRef = fireRef(getDatabase(),"/villiansgame/gameAction");
           // For some reason the onValue gets triggered twice if I don't call OFF here even if I have
           // never called "on". Don't know why that is happening. doesn't make sense.
           off(this.gameActionRef);

    //       if(this.listenGameDataFunction != null) {
             this.listenGameActionFunction = onValue(this.gameActionRef,  (snapshot) => {
               console.log("GAME Action HAS CHANGED");
                  const data = snapshot.val();
  //                console.log("data="+data);
                  this.gameActionChangedFunc(snapshot); // This is a function passed in to here
              });
            // } else {
            //   console.log("(setupGameDataListener) Listen function already set. Not setting again");
            // }
        }

//        console.log("(setupGameDataListener) COMPLETED");

     };
//
// Since PUSH from the DB doesn't seem to always work with Apple or certain networks,
// let's make a polling option and see if that makes things work better
     setupGameAndActionPolling = () => {
         if(this.MOCK_LOCAL) {
           console.log("(MOCK) setupPullGameAndActionPolling");
         } else {
// Every 10 seconds check the game data and game action
        this.gameStateManager.clearLastInterval();
        var pollId = setInterval(()=> {
                this.doGameDataPolling();
                setTimeout(()=> {
                   this.doGameActionPolling();
                },4000);
              },8000);

        this.gameStateManager.setLastPollingIntervalId(pollId);

         }
     };
//
// This will be the polling version to see if the players turn has changed.
     doGameDataPolling = () => {
       this.gameDataRef = fireRef(getDatabase(),"/villiansgame/gameData");
       get(this.gameDataRef).then((snapshot)=> {
          // Do something with the data if the players turn has changed. This should only
          // get done once each time the players turn changes.
            var theturn = this.gameStateManager.getWhichPlayersTurn();
            var val = snapshot.val();
            if(theturn !== val['playerNum']) {
               this.gameDataChangedFunc(snapshot); // This is a function passed in to here
            }
        }).catch((error) => {
            this.setError("Error Polling Game Data: "+error);
        });

     };
//
// This checks the action data that is the result of someone's turn and it may cause other
// players to have to do something.  Need to figure out how to make sure we only do this ONCE
// if something affects this player.
     doGameActionPolling = () => {
       this.gameActionRef = fireRef(getDatabase(),"/villiansgame/gameAction");
       get(this.gameActionRef).then((snapshot)=> {
         // See if there is anything for this player to do.
         var val = snapshot.val();
         if(this.gameStateManager.hasGameActionDataChanged(val)) {
           this.gameStateManager.setLastGameActionData(val);
           this.gameActionChangedFunc(snapshot); // This is a function passed in to here
         }

       }).catch((error) => {
           this.setError("Error Polling Action Data: "+error);
       });
     };
//
// Used to just get he current game and action data but do nothing with it
     getGameAndActionData = (tocall) => {
       this.gameDataRef = fireRef(getDatabase(),"/villiansgame/gameData");
       get(this.gameDataRef).then((snapshot)=> {

            var gameData = snapshot.val();

            this.gameActionRef = fireRef(getDatabase(),"/villiansgame/gameAction");
            get(this.gameActionRef).then((snapshot)=> {
               var actionData =snapshot.val();
               tocall(gameData,actionData); // This is a function passed in to here
            });



        });
     };

     getMessages = (tocall) => {
       var messRef = fireRef(getDatabase(),"/villiansgame/messages");
       get(messRef).then((snapshot)=> {
          var msgs =snapshot.val();
          tocall(msgs); // This is a function passed in to here
       });
     };
//
// Populate the error field in the database
     setError = (msg) => {
       var theRef = fireRef(getDatabase(),"/villiansgame/messages");
       var prom = update (theRef,{
           error:msg
       });
     };

     setInfo = (msg) => {
       var theRef = fireRef(getDatabase(),"/villiansgame/messages");
       var prom = update (theRef,{
           info:msg
       });
     };

     clearMessages = () => {
       var theRef = fireRef(getDatabase(),"/villiansgame/messages");
       var prom = update (theRef,{
           info:'',
           error:''
       });
     };


     bellOnOff = (onOff) => {
         this.gameUtils.bellOnOff(onOff);
     };


         debug = (func,msg)=> {
            console.log("("+func+") "+msg);
         };

  }

export default MyFirebase;
