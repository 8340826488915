import {getDatabase,onValue, ref as fireRef,off,update,get} from 'firebase/database';
import {Actions} from '../../constants';


class GameUtils {

   constructor(props) {

   }

   doPerformMagic = (action,player,player2=0) => {
     var theRef = fireRef(getDatabase(),"/villiansgame/gameAction");
     update (theRef,{
         playerNum:player,
         playerNum2:player2,     // default of 0 means not used
         actionType:action
     }).then(() => {
        console.log("Magic performed!!");
     }).catch((error) => {
        console.log("ERROR PERFORMING MAGIC: "+error);
     });

   };

   doPerformDataMagic = (action,player,data) => {
     var theRef = fireRef(getDatabase(),"/villiansgame/gameAction");
     update (theRef,{
         playerNum:player,
         playerNum2:0,     // default of 0 means not used
         actionType:action,
         data:data
     }).then(() => {
        console.log("Data Magic performed!!");
     }).catch((error) => {
        console.log("ERROR PERFORMING DATA MAGIC: "+error);
     });

   };

   bellOnOff = (onOff) => {
     var theRef = fireRef(getDatabase(),"/villiansgame/gameAction");
     update (theRef,{
         playerNum:0,
         playerNum2:0,     // default of 0 means not used
         actionType:onOff
     }).then(() => {
        console.log("Bell status changed!!");
     }).catch((error) => {
        console.log("ERROR Updating Bell status: "+error);
     });
   };

}

export default GameUtils;
