import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import {withFirebase} from '../Firebase';
import {Actions} from '../../constants';
import './admin.css';


function Admin(props) {

  const myFirebase = props.firebase;
  const [childList,setChildList] = useState([]);
  const [gameData,setGameData] = useState([]);
  const [actionData,setActionData] = useState([]);
  const [messages,setMessages] = useState([]);
  const gameController = props.gameController;

  // console.log(childList);
  // childList.forEach(function(val) {
  //   console.log("value="+val);
  // });

  function doListUsers(snapshot) {
    var tempList = [];
    // snapshot.forEach(function(childsnapshot) {
    //   console.log("child key="+childsnapshot.key);
    //   var childData = childsnapshot.val();
    //   // console.log("character="+childData['character']);
    //   // console.log("type="+childData['type']);
    //   // console.log("id="+childData['id']);
    //
    //   tempList.push({key:childsnapshot.key,...childData});
    // });
    // tempList = tempList.sort((a,b) => {
    //    return (a.id < b.id) ? -1:1;
    // });
    tempList = gameController.orderPlayers(snapshot,false);

   setChildList(tempList);
  }

  function showPlayers() {
    let html = [];
//    html.push(<table><tr><th>name</th><th>Character</th><th>Type</th></tr>);
    childList.forEach((child)=>{
       var temp = child; // .val();
       html.push(<tr key={child.key}><td>{child.key}</td><td>{temp['id']}</td><td>{temp['character']}</td><td>{temp['type']}</td></tr>);
    });
    // childList.forEach(function(child) {
    //   html.push(<tr key={child.key}><td>{child.key}</td><td></td><td></td>);
    //
    // });
    return html;
  }

// This is called in the HTML below to list the users
  function listUsers () {
     myFirebase.getCurrentUsers(doListUsers);
   }

   function resetPlayers() {
     myFirebase.clearAllUserData();
     setTimeout(()=> {
       listUsers();
     },2000);
   }

   function resetGame() {
     myFirebase.resetGame();  //
     setTimeout(()=> {
       getGameAndActionData();
     },2000);
   }

   function startGame() {
        gameController.startGame();

   }

   function bellOff() {
      myFirebase.bellOnOff(Actions.BELL_OFF);
   }

   function bellOn() {
      myFirebase.bellOnOff(Actions.BELL_ON);
   }

   function gameDataRetieved(gameData,actionData) {
     console.log("GOT THE GAME DATA");
     console.dir(gameData);
     setActionData(actionData);
     setGameData(gameData);
     console.dir(actionData);
   }

   function getGameAndActionData() {
     myFirebase.getGameAndActionData(gameDataRetieved);
   }

   function showGameData() {
     let html = [];
     if(gameData === null) {
       return;
     }
     //    html.push(<table><tr><th>name</th><th>Character</th><th>Type</th></tr>);

     html.push(<tr key='23'><td>{gameData['playerNum']}</td><td>{gameData['villianCardNum']}</td><td>{gameData['heroCardNum']}</td></tr>);
     // childList.forEach(function(child) {
     //   html.push(<tr key={child.key}><td>{child.key}</td><td></td><td></td>);
     //
     // });
     return html;
   }

   function showActionData() {
     let html = [];
     if(gameData === null) {
       return;
     }
     //    html.push(<table><tr><th>name</th><th>Character</th><th>Type</th></tr>);

     html.push(<tr key='24'><td>{actionData['actionType']}</td><td>{actionData['playerNum']}</td><td>{actionData['playerNum2']}</td></tr>);
     // childList.forEach(function(child) {
     //   html.push(<tr key={child.key}><td>{child.key}</td><td></td><td></td>);
     //
     // });
     return html;
   }

// TODO:
// Need to get whose turn it is and the number of players and pass in. Can make a function in
// MyFirebase that grabs that info from the GameStateManager
   function setNextPlayersTurn() {
     myFirebase.callPlayerTurnIsComplete();  //

   }

   function clearActionData() {
     myFirebase.clearActionData();
     setTimeout(()=> {
       getGameAndActionData();
     },2000);
   }

   function messagesRetrieved(val) {
     setMessages(val);
   }

   function getMessages() {
     myFirebase.getMessages(messagesRetrieved);
   }


    return(
      <div>
        <div className="adminHeader">
        <div className="adminTitle">Admin page</div>
        <div className="adminHeaderButton"><Link to="/">Return</Link></div>
        </div>
        <div>
        <button value="List Users" onClick={listUsers}>List Players</button>
        <button className="adminButton" value="Clear Player Data" onClick={resetPlayers}>Clear Player Data</button>
         <br/>
        <button className="adminResetButton adminButton" value="Reset Game" onClick={resetGame}>Reset Game</button>
        <button className="adminStartButton adminButton" value="Start Game" onClick={startGame}>Start Game</button>
         <br/>
        <button className="adminStartButton adminButton" value="Bell Off" onClick={bellOff}>Bell Off</button>
        <button className="adminStartButton adminButton" value="Bell On" onClick={bellOn}>Bell On</button>
        </div>
        <div className="dataDiv">

           <div>Current users:</div>
           <div>
            <table>
             <thead><tr><th>Name</th><th>ID</th><th>Character</th><th>Type</th></tr></thead>
             <tbody>
               {showPlayers()}
             </tbody>
            </table>
           </div>
          </div>

          <hr/>
          <button className="adminButton" value="Get Game Data" onClick={getGameAndActionData}>Get Game Data</button>
          <button className="adminButton adminStartButton" value="Next Player" onClick={setNextPlayersTurn}>Next Player's Turn</button>

          <div className="dataDiv">

             <div>Game Data:</div>
             <div>
              <table style={{borderCollapse:'collapse'}}>
               <thead style={{border:'1px solid black'}}><tr><th style={{border:'1px solid black'}}>Player Turn</th><th style={{border:'1px solid black'}}>Hero Card</th><th style={{border:'1px solid black'}}>Villian Card</th></tr></thead>
               <tbody>
                 {showGameData()}
               </tbody>
              </table>
             </div>
             <br/><br/>
             <button className="adminButton adminStartButton" value="Clear Action Data" onClick={clearActionData}>Clear Action Data</button>

             <div>
              <table style={{borderCollapse:'collapse'}}>
               <thead style={{border:'1px solid black'}}><tr><th style={{border:'1px solid black'}}>Action</th><th style={{border:'1px solid black'}}>Player Num 1</th><th style={{border:'1px solid black'}}>Player Num 2</th></tr></thead>
               <tbody>
                 {showActionData()}
               </tbody>
              </table>
             </div>
          </div>

          <hr/>
          <button className="adminButton adminStartButton" value="Get Messages" onClick={getMessages}>Get Messages</button>

          <div style={{textAlign:'left'},{fontWeight:'bold'}}>ERROR</div>
          <div style={{border: '2px solid red'}}>
           <div>
             {messages['error']}
           </div>
          </div>
<br/>
          <div style={{textAlign:'left'},{fontWeight:'bold'}}>INFO</div>
          <div style={{border: '2px solid blue'}}>
           <div>
             {messages['info']}
           </div>
          </div>
     <br/><br/>
     <div style={{textAlign:'center'}}>Version 1.2.5</div>
      </div>
    );

};

export default withFirebase(Admin);
