 import {heroes,villains} from '../../constants/characters.js';
import {HeroCards,VillainCards,Actions,ActionCards} from '../../constants';
//
//  /villians/game/cards/heroes
//        text - Text of the card to show
//        action - Action to perform related to card, if any. like casting
//                 a spell or swapping characters with someone else.
//        action2 - In case there are multiple actions
//
//  /villians/game/cards/villians
//     (same as above)
//


class GameController {

   constructor(firebase,gsm) {
     this.myFirebase = firebase;
     this.gameStateManager = gsm;
//     this.currentPlayers = [];
     this.playersInOrder = [];

// DEBUG - Uncomment this when done debugging the first card
     this.shuffleCards(HeroCards);
     this.shuffleCards(VillainCards);



   }

   shuffleCards = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
   };

   chooseCharacter = (username) => {
     var char = "NOTHING";
     var whichVal=0;
     var charType = "hero";
     // Get list of current users and their character from firebase
//     this.myFirebase.getCurrentUsers().then
    var hov = this.randomNum(50);
    if(hov < 25) {
      whichVal = this.randomNum(heroes.length);
      char = heroes[whichVal];
    } else {
      whichVal = this.randomNum(villains.length);
      char = villains[whichVal];
      charType = "villain";
    }
    if(username !== 'UNNAMED') {
       this.myFirebase.updateUserCharacter(username,char,charType);
    }
    return {name:char,type:charType,index:whichVal};
   };



   continueChooseCharacter = (players)=> {
     var char = "NOTHING";
     var whichVal=0;
     var charType = "hero";

     var tempList = [];
      players.forEach((child)=> {
        var childData = child.val();
        tempList.push({key:child.key,...childData});
      });


     var chosenChar = this.randomlyPickCharacter()
     // console.log("chosen character="+chosenChar.name+" type="+chosenChar.type);
     var sanityCheck = 0;
     var val = tempList.find(elem => elem.character === chosenChar.name);
     while(typeof val !== 'undefined' && sanityCheck < 20) {
       // Already have this char so choose again
         chosenChar = this.randomlyPickCharacter()
         val = tempList.find(elem => elem.character === chosenChar.name);
         sanityCheck++; // Only try 20 times then just give up

     }


     if(this.username !== 'UNNAMED') {
        this.myFirebase.updateUserCharacter(this.username,chosenChar.name,chosenChar.type);
     }
     this.gameStateManager.setCharacterType(chosenChar.type,false);
     this.gameStateManager.setCharacter(chosenChar.name,false);
     this.gameStateManager.setCharacterIndex(chosenChar.index,true);

     return {name:chosenChar.name,type:chosenChar.type,index:chosenChar.index};
   };

   chooseUniqueCharacter = (username) => {
     this.username = username; // We will need it later
     // Get list of current users and their character from firebase
    var prom = this.myFirebase.getCurrentUsersAsProm();
    prom.then((snapshot)=> {
       var val = this.continueChooseCharacter(snapshot);
       // this.gameStateManager.setCharacterType(val.type,false);
       // this.gameStateManager.setCharacter(val.name,false);
       // this.gameStateManager.setCharacterIndex(val.index,false);
     });

   };

   randomlyPickCharacter = () => {
     var char = "NOTHING";
     var whichVal=0;
     var charType = "hero";

     var hov = this.randomNum(50);

     if(hov < 25) {
       whichVal = this.randomNum(heroes.length);
       char = heroes[whichVal];
     } else {
       whichVal = this.randomNum(villains.length);
       char = villains[whichVal];
       charType = "villain";
     }
     return {name:char,type:charType,index:whichVal};
   };


//
// Start the game
   startGame = () => {
       var prom = this.myFirebase.startGame();
       prom.then(()=> {
         console.log("(startGame) Game Started");
       }).catch((error) => {
         console.log("(startGame) Caught Error: "+error);
       });
   };
//
// Game started so read in all the players and then put them in order. this way we can tell
// when it is our turn
   createPlayerOrder = () => {
      var prom = this.myFirebase.getCurrentUsersAsProm();
      prom.then((snapshot)=> {
         this.doCreatePlayerOrder(snapshot);
       });
      return prom;
   };

   doCreatePlayerOrder = (snapshot) => {
     this.playersInOrder = this.orderPlayers(snapshot);
   };

   getNumberOfPlayers = () => {
       return this.playersInOrder.length;
   };

   orderPlayers = (players,remove=true) => {
     var tempList = [];
      players.forEach((child)=> {
        var childData = child.val();
        tempList.push({key:child.key,...childData});
      });
      tempList = tempList.sort((a,b) => {
         return (a.id < b.id) ? -1:1;
      });
      // finally, remove any players with an ID of 0 as that means they are not playing
      if(remove) {
        this.removeNonePlayingPlayers(tempList);
      }
      return tempList;
   };

   removeNonePlayingPlayers = (array) => {
     var index = array.find(elem=> elem.id === 0);
     while(index !== undefined) {
       array.splice(index,1);  // Remove 1 elemenet starting at index
       index = array.find(elem=> elem.id === 0);
     }

   };
//
// based on which (hero or villain) return the player number of a random DONE
// of that type.
  choosePlayerByType= (which)=> {
     var ret =0;
     var tmp=[];
      for(var i=0;i<this.playersInOrder.length;i++) {
         if(this.playersInOrder[i].type === which) {
           tmp.push(i)
         }
      }
      var val = this.getRandomInt(tmp.length);
      ret = tmp[val]+1; // Because player numbers go from 1 to X, not 0 to X-1
      return ret;
   };
//
// Another player swapped characters and a notice was sent here to update the players list
   updatePlayerCharacterSwap=(player,name,type)=> {
       this.playersInOrder[player-1].character = name;
       this.playersInOrder[player-1].type = type;
       // We are not updating THIS player so no need to do anything else.
   };


   getRandomInt=(max,nothis=-1)=> {
      var val = 0;
      if(nothis === -1) {
         val = Math.floor(Math.random() * max) ;
       } else if(max > 1) {  // to avoid endless loop
         while(val !==nothis) {
           val = Math.floor(Math.random() * max) ;
         }
       }
      return val;
   };

   // findPlayerByName = (player) => {
   //    return
   // };

   getPlayerNumberFor = (name) => {
     // var val = 0;
     // console.log("(getPlayerNumberFor) name="+name);
     //   val = this.playersInOrder.findIndex((player)=> {
     //     console.log("name="+name+" key="+player.key);
     //     return (player.key === name) ;
     //   });
       var val = 0,retVal=0;
        this.playersInOrder.forEach(function(player) {
          val++;
          if(name === player.key) {
             retVal = val;
          }
        });
       return retVal;
   };

// If it is this players turn then draw a card
   playIfYourTurn = (values) => {
      var ret = false;
       if(this.gameStateManager.getPlayerNumber() === values.playerNum) {
//         console.log("======It's your turn. Hurray!");
//         console.log("cardnum="+values.heroCardNum);
         ret = true;
         this.drawCard(this.gameStateManager.getCurrentHeroCardNumber(),
                       this.gameStateManager.getCurrentVillainCardNumber());
       } else {
         console.log("======It's NOT your turn!");

       }
       return ret;
   };
//
// Play the indicated action card for this user.
   playActionCard = (actionData) => {
       // Get a cards for this type of action
       var card = this.findActionCardsFor(actionData.actionType);
       if(card.cardType === Actions.SWAP_BODIES_SPELL) {
         this.performSwapBodiesSpell(actionData);
       } else if(card.cardType === Actions.HERO_TO_VILLAIN || card.cardType === Actions.VILLAIN_TO_HERO) {
         this.performCharacterChange(card.cardType);
       }
       this.gameStateManager.setCard(card);
   };
//
// This player will become a new character based on 'which'
   performCharacterChange = (which) => {
     var prom = this.myFirebase.getCurrentUsersAsProm();
     prom.then((snapshot)=> {
        var players = this.orderPlayers(snapshot);
        this.doPerformCharacterChange(which,players);
      });
   };
//
// 1 - Heros, 2 - Villain
// players - list of the current players and their characters.
   pickUniqueHeroOrVillain = (which,players) => {
//     console.log("===== Picking unique hero or villan: "+which);
       var sanityCheck = 0;
       var chosenChar,whichVal;
       if(which === 1) {
         whichVal = this.randomNum(heroes.length);
         chosenChar = heroes[whichVal];
       } else {
         whichVal = this.randomNum(villains.length);
         chosenChar = villains[whichVal];
       }
       var val = players.find(elem => elem.character === chosenChar);
       while(typeof val !== 'undefined' && sanityCheck < 20) {
         // Already have this char so choose again
           if(which === 1) {
             whichVal = this.randomNum(heroes.length);
             chosenChar = heroes[whichVal];
           } else {
             whichVal = this.randomNum(villains.length);
             chosenChar = villains[whichVal];
           }
           val = players.find(elem => elem.character === chosenChar);
           sanityCheck++; // Only try 20 times then just give up

       }

//    console.log("Choose character: "+chosenChar);

       return whichVal;
     }


   doPerformCharacterChange = (which,players) => {
     var whichVal=0,char,charType;
      if(which === Actions.HERO_TO_VILLAIN) {
        // choose a villain
          whichVal = this.pickUniqueHeroOrVillain(2,players);
//          whichVal = this.randomNum(villains.length);
          char = villains[whichVal];
          charType = "villain";

      } else  {
        // choose a hero
         whichVal = this.pickUniqueHeroOrVillain(1,players);

          // whichVal = this.randomNum(heroes.length);
          char = heroes[whichVal];
          charType = "hero";
      }
      var playerNum = this.gameStateManager.getPlayerNumber();
//      console.log("(performCharChange) playerNum="+playerNum);
      this.playersInOrder[playerNum-1].character = char;
      this.playersInOrder[playerNum-1].type = charType;
      this.gameStateManager.setCharacter(char,false); // Don't broadcast until next set
      this.gameStateManager.setCharacterType(charType);
      this.gameStateManager.setCharacterIndex(whichVal);
      // Update the data in Firebase?
      var prom = this.myFirebase.updateUserCharacter(this.gameStateManager.getPlayerName(),char,charType,false);
      prom.then(()=> {
         this.myFirebase.tellPlayersOfCharacterSwap(playerNum,char,charType);
      });
      // Now we should fire off an event that will tell all the players to reload the playersInOrder list
      // or alternately just change the person that changed.
   };
//
// A spell was cast and the players need to swap characters
   performSwapBodiesSpell = (data) => {
     var playerToBecome,tchar,ttype;
     var spellCaster = data.playerNum;
     var swapWith = data.playerNum2;
//     console.log("spellcaster="+spellCaster+ " swapWith="+swapWith);
//     console.log("length of players array="+this.playersInOrder.length);

     if(this.gameStateManager.getPlayerNumber() === spellCaster) {
       playerToBecome = this.playersInOrder[swapWith-1];
       tchar = playerToBecome.character;
       ttype = playerToBecome.type;
       this.playersInOrder[swapWith-1].character = this.playersInOrder[spellCaster-1].character;
       this.playersInOrder[swapWith-1].type = this.playersInOrder[spellCaster-1].type;
       this.playersInOrder[spellCaster-1].character = tchar;
       this.playersInOrder[spellCaster-1].type = ttype;

     } else {
       playerToBecome = this.playersInOrder[spellCaster-1];
       tchar = playerToBecome.character;
       ttype = playerToBecome.type;
       this.playersInOrder[spellCaster-1].character = this.playersInOrder[swapWith-1].character;
       this.playersInOrder[spellCaster-1].type = this.playersInOrder[swapWith-1].type;
       this.playersInOrder[swapWith-1].character = tchar;
       this.playersInOrder[swapWith-1].type = ttype;


     }
//     console.log("playerToBecome="+playerToBecome);
// Since this is the player that cast the spell, update the characters in the database,
// and then tell everyone else to update their characters list
      var prom = this.myFirebase.updateUserCharacter(this.gameStateManager.getPlayerName(),tchar,ttype,false);
      prom.then(()=> {
             this.myFirebase.tellPlayersOfCharacterSwap(this.gameStateManager.getPlayerNumber(),tchar,ttype);
      });

     this.gameStateManager.setCharacter(tchar,false); // Don't broadcast until next set
     this.gameStateManager.setCharacterType(ttype,false);
     this.gameStateManager.setCharacterIndex(this.findIndexOf(tchar,ttype),true);

   };
//
// Given a character name, find the index into the characters array
   findIndexOf = (char,type) => {
     var index = -1;
     if(type === 'hero') {
         index = heroes.findIndex(elem=>elem === char);
     } else {
       index = villains.findIndex(elem=>elem === char);
     }
     console.log("(findIndexOf) char="+char+" index="+index);
     return index;
   };

   findActionCardsFor = (type) => {
     var ret = null;
     console.log("action type = "+type);
     var cards = ActionCards.filter(card => card.cardType === type );
     console.log("cards="+cards+" length="+cards.length);
     ret = cards[0];
     if(cards.length > 1) {
       ret = cards[this.randomNum(cards.length)];
     }
     console.log("card returned="+ret);
     return ret;
   };
//
// Draw a card
// heroCardNum - Which hero card to draw if we are a heroe
// villianCardNum - Which villan card to draw if we are a villian
   drawCard = (heroCardNum,villainCardNum) => {

     if(this.gameStateManager.isHero()) {
       this.gameStateManager.setCard(HeroCards[heroCardNum]);
       this.gameStateManager.incrementHeroCardNumber();
     } else {
       this.gameStateManager.setCard(VillainCards[villainCardNum]);
       this.gameStateManager.incrementVillainCardNumber();
     }

   };

   findFirstVillain = () => {
     var nump = this.gameStateManager.getNumberOfPlayers();
     var playerToChange = this.gameStateManager.getPlayerNumber()+1;
     var isVillain = false;
     var sanityCheck = 0;
     while(!isVillain && sanityCheck < 10) {
       if(playerToChange > nump) {
         playerToChange = 1;
       }
       if(this.playersInOrder[playerToChange-1].type === 'villain') {
         isVillain = true;
         console.log("(findFirstVillain) Found a villian at "+playerToChange);
       } else {
         playerToChange++;
       }
       sanityCheck++;
     }
     console.log("(findFirstVillain) returning="+playerToChange);
     return playerToChange;
   };

// Return a radmon number between 0 and (max-1)
   randomNum = (max) => {
     return Math.floor(Math.random() * max);
   };

}

export default GameController;
