import React, {Component} from 'react';
import VillainsGame from './index'
import GameHeader from '../GameHeader'
import {withFirebase} from '../Firebase';
import SoundManager from '../Utils/SoundManager';

class GameContainer extends Component {

  constructor(props) {
    super(props);
    this.gameStateManager = props.gameStateManager;
    this.myFirebase = props.firebase;
    this.gameController = props.gameController;
    this.userName = this.gameStateManager.getPlayerName();
    this.broadCast = () => {
      // console.log("inside broadcast");
      this.setState(this.gameStateManager.getCurrentState());

    }

    this.soundManager = new SoundManager();
    this.soundFinish = (id,evt) => { this.doSoundFinished(id,evt) };

    this.soundManager.loadSprite('gamesounds','game_sounds.mp3',
              { bell:[0,1000],
                magic1: [1000,2000],
                magic2: [3000,7500],
                performMagic:[9000,2200],
                darkMagic1:[11300,2000],
                darkMagic2:[13500,2500],
                darkMagic3:[16100,2200],
                evilLaugh1:[19000,1500],
                evilLaugh2:[22000,2400],
                evilLaugh3:[25000,1300]
             },this.soundFinish);

    this.gameStateManager.setBroadcast(this.broadCast);

    this.joinGame();

  }

  doSoundFinished = (id,evt) => {
    var i=1;
  //  console.log("(gameContainer)SOUND FINISHED");
  };

  joinGame = () => {
//    console.log("start the game now user="+this.userName);

    // var val = this.gameController.chooseCharacter(this.userName);
    // this.gameStateManager.setCharacterType(val.type,false);
    // this.gameStateManager.setCharacter(val.name,false);
    // this.gameStateManager.setCharacterIndex(val.index,false);

    this.gameController.chooseUniqueCharacter(this.userName);

    this.showStartButton = false;
    if(this.userName === "scott") {
      this.showStartButton = true;
    }


  };

  componentDidMount() {
//    console.log("(componentDidMount) Setup game listener");
    this.myFirebase.setupGameListeners();
  }

  startGame = () => {
     console.log("(startGame) Game container starting the game");
     this.gameController.startGame();
  };

  render() {
    return(
      <div>
        <GameHeader  playerName={this.gameStateManager.getPlayerName()}
          playerCharacter={this.gameStateManager.getCharacter()}
          playerType={this.gameStateManager.getCharacterType()}
          startGame={this.startGame}/>
        <VillainsGame gameStateManager={this.gameStateManager}
          gameController={this.gameController} soundManager={this.soundManager}/>
      </div>

    );
  }
}

export default withFirebase(GameContainer);
