export const NO_ACTION = 0;
export const CAST_SPELL = 1;
export const PERFORM_MAGIC = 2;  // Perform magic of some type
export const PICK_OR_TAKE_ACTION = 3;  // Pick someone else to pick a gift or take someone elses
export const TRADE_SPELL = 4;  // 2 people trade gifts
export const MULTI_TRADE_SPELL=5; // Force 2 random people to trade.
export const RELINQUISH_GIFT_SPELL=6;  // Force a player to put his gift, open or not, back into the pile.
export const SWAP_BODIES_SPELL=7; // Swap gifts and characters with someone
export const HERO_VILLAIN_SWAP = 8;
export const VILLAIN_HERO_SWAP=9;
export const OPEN_GIFT=10;
export const VILLAIN_TO_HERO=11;
export const HERO_TO_VILLAIN=12;
export const TAKE_OR_OPEN_GIFT=13;
export const OPTION_A_OR_B=14;
export const BECOME_HERO=15;
export const BECOME_VILLAIN=16;


// These are admin type actions, not game actions
export const BELL_ON=80;
export const BELL_OFF=81;
export const CHAR_SWAP=2;
