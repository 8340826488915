

class Logger {

  constructor(baseName) {
    this.baseClassName = baseName;
    this.doLogging = false;
  }
  //////////////////////////////////////
  debug(mkr) {
    console.log("<"+this.baseClassName+">");
    for(var key in mkr) {
      console.log(key+"="+mkr[key]);
    }
  }
     log = (func,msg) => {
       if(this.doLogging) {
         console.log("("+this.baseClassName+":"+func+") "+msg);
       }
     }

}

export default Logger;
