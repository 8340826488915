import React, {Component} from 'react';
import {withGameState} from '../../GameState';
import {Link} from 'react-router-dom';
import GameHeader from '../GameHeader';
import './chooseCharacter.css';

class ChooseCharacter extends Component {

  constructor(props) {
    super(props);
    this.gameStateManager = props.gameStateManager;
    this.setUserFunction = this.props.setUser;
    this.joinGameFunction = this.props.joinGame;
    this.startGameFunction = this.props.startGame;
    this.state = {playerName:this.gameStateManager.getPlayerName(),
                  playerCharacter:this.gameStateManager.getCharacter(),
                  playerType:this.gameStateManager.getCharacterType(),
                  joinGameClass:'linkButtonHide',
                  chooseUserClass:'chooseUserClass',
                  chooseUserButtonClass:'chooseButtonHide',
                  showAdminButton:false};
  }

  userChosen=(username)=> {
       var sab = false;
       this.gameStateManager.setPlayerName(username);
       if(username === 'admin') {
         sab = true;
       }
       this.setState({playerName:username,showAdminButton:sab});
  };

  showChooseUser = () => {
    this.setState({chooseUserClass:'chooseUserClass',
                   chooseUserButtonClass:'chooseButtonHide'});
  };

  joinTheGame=() => {
    this.setState({chooseUserClass:'chooseUserClassHidden',
                   chooseUserButtonClass:'chooseButtonShow'});

    this.joinGameFunction();
  };

  showStartButton = () => {

    var ret = "hideStartButton";
    if(this.props.showStartButton) {
      ret = "showStartButton";
    }
    return ret;
  };

  startGame = () => {
    this.startGameFunction(); // Call start in the main game

  };

  showJoinButton = () => {
    var val = "linkButtonHide";
    if(this.state.playerName !== "UNNAMED" && this.state.playerName !== "CHOOSE") {
      val = "linkButton";
    }
    return val;
  }

  render() {
      return (
        <div>

        <GameHeader playerName={this.state.playerName} playerCharacter={this.state.playerCharacter}
          playerType={this.state.playerType}/>

        <div className={this.state.chooseUserClass}>
          <div className="chooseGameHeader">
          Welcome to the Heroes vs Villains game.
          </div>
            <label>Choose your player: </label>
            <select name='userSelect' id='userSelect' onChange={(e)=> this.userChosen(e.target.value)}>
              <option value="CHOOSE">CHOOSE</option>

              <option value="ashe">Ashe</option>
              <option value="brad">Brad</option>
              <option value="brian">Brian</option>
              <option value="corbin">Corbin</option>
              <option value="dennis">Dennis</option>
              <option value="diane">Diane</option>
              <option value="gene">Gene</option>
              <option value="jennifer">Jennifer</option>
              <option value="lucas">Lucas</option>
              <option value="merrilee">Merrilee</option>
              <option value="pax">Pax</option>
              <option value="scott">Scott</option>
              <option value="shea">Shea</option>
              <option value="chris">Chris</option>
              <option value="sara">Sara</option>
              <option value="admin">Admin</option>

            </select>
            <br/>
            <hr/>
            <Link className={this.showJoinButton()} to="/joinGame">Join Game</Link>
            <Link style={{display:this.state.showAdminButton ? 'inline':'none'}} to="/admin">Admin</Link>
         </div>
        </div>
      );
  }
}

export default withGameState(ChooseCharacter);
