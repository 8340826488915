import React from 'react';
import {HeroCards, VillainCards} from '../constants/gameCards';

export const initialGameState = {
   playerName: "UNNAMED",
   character: "NONE",
   characterType: "NONE",
   characterIndex:0,
   id:0,
   playerNumber:0,   // 0 indicates no number set yet
   whichPlayersTurn:0, // Which players turn is it
   isAdmin: false,
   currentHeroCardNumber:0,  // Which card to draw.
   currentVillainCardNumber:0,
   numberOfHeroCards:HeroCards.length,      // TODO: Set this and it doesn't change
   numberOfVillainCards:VillainCards.length,   // Set this
   currentCard: {text:"NO CARD",action:-1}  // Current card to be played
   // closeStaticViewCB: ()=>{},
   // changeViewCB: ()=>{},

};



const GameStateContext = React.createContext(initialGameState);

export default GameStateContext;

export const withGameState = Component => props => (
  <GameStateContext.Consumer>
    {gameStateManager => <Component {...props} gameStateManager={gameStateManager} />}
  </GameStateContext.Consumer>
);
