import React, {Component} from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';

import './App.css';
import GameContainer from './components/Villains/GameContainer';
import ChooseCharacter from './components/ChooseCharacter';
import GameController from './components/Utils/GameController';
import Admin from './components/Admin';

import {withGameState} from './GameState';
import {withFirebase} from './components/Firebase';

//
// Database design
//  /villiansgame/gameData
//           villainCardNum - Which villain card is in play
//           heroCardNum - Which hero card was last played
//           playerNum - Whose turn is it?
//
//  /villainsgame/cards/heroes
//        text - Text of the card to show
//        action - Action to perform related to card, if any. like casting
//                 a spell or swapping characters with someone else.
//        action2 - In case there are multiple actions
//
//  /villains/game/cards/villains

class App extends Component {

  constructor(props) {
    super(props);
    this.user = "NONE";
    this.myFirebase = props.firebase;
    this.gameStateManager = props.gameStateManager;
    this.myFirebase.setGameStateManager(this.gameStateManager);
    this.gameController = new GameController(this.myFirebase,this.gameStateManager);
  }

  userChosen(username) {
       this.user = username;
       console.log("username="+username);
  }

  render() {
      return (
        <Router basename="/">
          <div className="App">
            <Routes>
               <Route path="/" element={<ChooseCharacter/>}/>
               <Route path="/admin" element={<Admin firebase={this.myFirebase} gameController={this.gameController}/>}/>
               <Route path="/joinGame" element={<GameContainer gameStateManager={this.gameStateManager} gameController={this.gameController}/>}/>
            </Routes>
          </div>
        </Router>
      );
  }
}

export default withFirebase(withGameState(App));
