import React, {Component} from 'react';
import {withGameState} from '../../GameState';
import { Link } from 'react-router-dom';

import './joinGame.css';

class GameHeader extends Component {

  constructor(props) {
    super(props);
    this.setUserFunction = this.props.setUser;
    this.joinGameFunction = this.props.joinGame;
    this.startGameFunction = this.props.startGame;
    this.gameStateManager = props.gameStateManager;
    this.state = {playerName:props.playerName,
                  playerCharacter:props.playerCharacter,
                  playerType:props.playerType,
                  joinGameClass:'joinGame',
                  chooseUserClass:'chooseUserClass',
                  chooseUserButtonClass:'chooseButtonHide',
                  characterGameHeaderClass:'gameHeader'};

// TODO: If you are a villain and then restart the game and choose a new character and become a hero (or vice-versa)
// the header still thinks you are the previous character type because it doesn't it displays before the
// update happens. So the color of the header is wrong.
  }


  showChooseUser = () => {
    this.setState({chooseUserClass:'chooseUserClass',
                   chooseUserButtonClass:'chooseButtonHide'});
  };

  joinTheGame=() => {
    console.log("inside join game");
    this.setState({chooseUserClass:'chooseUserClassHidden',
                   chooseUserButtonClass:'chooseButtonShow'});

    this.joinGameFunction();
  };

  showStartButton = () => {

    var ret = "hideStartButton";
    if(this.props.playerName === 'scott' && this.props.playerCharacter !== 'NONE') {
      ret = "showStartButton";
    }
    return ret;
  };

  startGame = () => {
    this.startGameFunction(); // Call start in the main game

  };



  combinedPlayerName = () => {
     return this.props.playerName +" - " +this.props.playerCharacter;
  };

  getGameHeaderClass = () => {
    var ret = "gameHeader";
     if(this.state.playerType === 'villain') {
       ret = "gameHeaderVillain";
     }
  //   console.log("(getGameHeaderClass) player type="+this.state.playerType+" header="+ret);
     return ret;
  };

  render() {
      return (
        <div className={this.state.joinGameClass}>
          <div className={this.getGameHeaderClass()}>
          <div className={this.state.chooseUserButtonClass} onClick={this.showChooseUser}>Choose User</div>
          <div className={this.showStartButton()} onClick={this.startGame}>Start Game</div>
          <div className={this.showStartButton()} onClick={this.admin}>
            <Link to="/admin">Admin</Link>
          </div>
           <div className="currentUser">User: {this.combinedPlayerName()}</div>
          </div>

        </div>
      );
  }
}

export default withGameState(GameHeader);
