import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Firebase,{FirebaseContext} from './components/Firebase';
import {GameStateContext} from './GameState';
import GameStateManager from './GameState/GameStateManager';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FirebaseContext.Provider value={new Firebase()}>
   <GameStateContext.Provider value={new GameStateManager()}>
      <App />
   </GameStateContext.Provider>
  </FirebaseContext.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
