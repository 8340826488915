
import { initialGameState } from './gameContext';
import {heroIcons,villainIcons} from '../constants/characters';
//
// Manage the state of the game.
//


class GameStateManager  {

  constructor(firebase,pushUpdates) {
    console.log("GSM Constructor");
      this.gameState = {...initialGameState};
      this.firebase = firebase;

      this.broadcastChanges = null;

      this.lastGameActionData = {};  // This will hold the last snapshot found

      this.lastPollingIntervalId = -1; // Gets set if using POLLING (see MyFirebase)

  }

  setBroadcast = (func) => {
     this.broadcastChanges = func;
  };
//
// the GameView calls this and passes a function within GameView that will change state to open a game
  getPlayerName = () => {
    return this.gameState.playerName;
  };

  setPlayerName = (nm,doBroadcast=true) => {
    this.updateGameState("playerName",nm,doBroadcast);
  };

  setPlayerNumber = (nm) => {
    this.updateGameState("playerNumber",nm,false);
  };

  getPlayerNumber = () => {
    return this.gameState.playerNumber;
  };

  setNumberOfPlayers = (num) => {
    this.updateGameState("numberOfPlayers",num,false);
  }

  getNumberOfPlayers = () => {
    return this.gameState.numberOfPlayers;
  }

  getPlayerId = () => {
    return this.gameState.id;
  };

  setPlayerId = (id,doBroadcast=true) => {
    this.updateGameState("id",id,doBroadcast);
  };

  getCharacter = () => {
    return this.gameState.character;
  };

  setCharacter = (nm,doBroadcast=true) => {
    this.updateGameState("character",nm,doBroadcast);
  };

  getCharacterType = () => {
    return this.gameState.characterType;
  };

  setCharacterType = (nm,doBroadcast=true) => {
    this.updateGameState("characterType",nm,doBroadcast);
  };

  setCharacterIndex = (ind,doBroadcast=false) => {
    this.updateGameState("characterIndex",ind,doBroadcast);
  };

  getCharacterIcon = () => {
     var icon = "";
     if(this.isHero()) {
       icon = heroIcons[this.gameState.characterIndex];
     } else {
       icon = villainIcons[this.gameState.characterIndex];

     }
     return icon;
  };

  isHero = () => {
    return ((this.gameState.characterType === "hero") ? true:false);
  };

  setCard = (card) => {
     this.updateGameState("currentCard",card);
  };

  getCard = () => {
    return this.gameState.currentCard;
  };

  setCurrenCardNumbers = (hero,villain) => {
     this.updateGameState("currentHeroCardNumber",hero,false);
     this.updateGameState("currentVillainCardNumber",villain,false);
  };

  getCurrentHeroCardNumber = () => {
    return this.gameState.currentHeroCardNumber;
  };

  getCurrentVillainCardNumber = () => {
    return this.gameState.currentVillainCardNumber;
  };
//
// Need to make sure we don't go over number of cards we have.
  incrementHeroCardNumber = () => {
    var num = this.gameState.currentHeroCardNumber +1 ;
    if(num >= this.gameState.numberOfHeroCards) {
      num = 0;
    }
    this.updateGameState("currentHeroCardNumber",num,false);
  }

  incrementVillainCardNumber = () => {
    var num = this.gameState.currentVillainCardNumber +1 ;
    if(num >= this.gameState.numberOfVillainCards) {
      num = 0;
    }

    this.updateGameState("currentVillainCardNumber",num,false);
  }

  setWhichPlayersTurn = (which) => {
    this.updateGameState("whichPlayersTurn",which,false);
  }

  getWhichPlayersTurn = () => {
    return this.gameState.whichPlayersTurn;
  };

  isThisPlayersTurn = () => {
    return (this.gameState.whichPlayersTurn === this.gameState.playerNumber);
  }

  updateGameState = (key,val,doBroadcast=true) => {
    this.gameState[key] = val;
//    console.log("doBroadcast="+doBroadcast);
    if(this.broadcastChanges != null && doBroadcast) {
//      console.log("CALLING BROADCASTCHANGES");
       this.broadcastChanges();
    }
  }

  changeGameStateValue = (obj) => {
     for(const [key,value] of Object.entries(obj)) {
       this.updateGameState(key,value);
     }
  };

  getCurrentState = () => {
     return this.gameState;
  };
//
// data - This should be snapshot.val(), not the actual snapshot
  setLastGameActionData = (data) => {
    this.lastGameActionData = data;
  };
//
// Compare the game action data to see if anything has changed.
  hasGameActionDataChanged = (data) => {
//    console.log("(hasGameActionDataChanged) top");
    var ret = false;
    var found = 0;
    if(data['actionType'] === this.lastGameActionData['actionType']) {
       found++;
    }
    if(data['playerNum'] === this.lastGameActionData['playerNum']) {
        found++;
    }
    if(data['playerNum2'] === this.lastGameActionData['playerNum2']) {
        found++;
    }

    if(found < 3) {
      ret = true;
    }

//    console.log("(hasGameActionDataChanged) bottom");

    return ret;

  };
//
// Set the last polling interval ID
  setLastPollingIntervalId = (id) => {
     this.lastPollingIntervalId = id;
  };

  clearLastInterval = () => {
    if(this.lastPollingIntervalId !== -1) {
      clearInterval(this.lastPollingIntervalId);
      this.lastPollingIntervalId = -1;
    }
  };


////////////////////////////////////////////////////////
//


}

export default GameStateManager;
