import React, {Component} from 'react';
import {withFirebase} from '../Firebase';
import {Link} from 'react-router-dom';
import GameCard from '../Utils/GameCard';
import {Actions} from '../../constants';

import './villains.css';


class VillainsGame extends Component {
  constructor(props)  {
    super(props);
    this.gameStateManager = props.gameStateManager;
    this.showStartButton = false;
    this.gameController = props.gameController;
    this.playBell = true; // By default bell is on
    var mockButtonClass = "hideMockDataButton";
    var playerName = this.gameStateManager.getPlayerName();
    if(playerName === 'scott') {
       mockButtonClass = "mockDataButton";
    }
    this.state = {
              villainsGameClass:'villainsGameShow',
              mockDataChangeButtonClass:'hideMockDataButton',
              showGameCard:false
              };
    this.myFirebase = props.firebase;

    this.soundManager = props.soundManager;

    // This function is called when game data changes in the database
    this.myFirebase.setGameDataChangedFunc(this.gameDataChanged);
    this.myFirebase.setGameActionChangedFunc(this.gameActionChanged);

    if(playerName === "UNNAMED") {
      console.log("Something is wrong. Go back to choose person");
    }
  }



  setTheUser = (user) => {
      this.userName = user;
      console.log("IN THE villains USER="+user);
  };

  joinGameNotUsed = () => {
    console.log("start the game now user="+this.gameStateManager.getPlayerName());

    var val = this.gameController.chooseCharacter(this.userName);
    this.gameStateManager.setCharacter(val.type);
    this.gameStateManager.setCharacter(val.name);

    this.showStartButton = false;
    if(this.userName === "scott") {
      console.log("setting show start button to true");
      this.showStartButton = true;
    }

    this.setState({villainsGameClass:'villainsGameShow',
                  characterName:this.characterName});

  };

  initializePlayersGameState = async(whichPlayersTurn) => {
    await this.gameController.createPlayerOrder();
    var playerNum = this.gameController.getPlayerNumberFor(this.gameStateManager.getPlayerName());
console.log("PLAYER NUM="+playerNum);
    this.gameStateManager.setNumberOfPlayers(this.gameController.getNumberOfPlayers());
    this.gameStateManager.setPlayerNumber(playerNum);

  };

//////////////////////////////////////////////////////////////
//
  gameDataChanged = async (snapshot) => {
//      console.log("(gameDataChanged) DO SOMETHING WITH DATA");
      // If active player is 0 then do nothing as it's just a game reset
      var val = snapshot.val();
      var whichPlayersTurn = val['playerNum'];
      if(whichPlayersTurn === 0) {
        console.log("GAME WAS RESET");
        this.gameStateManager.setPlayerNumber(0); // So it will reinit player info
        this.gameStateManager.setWhichPlayersTurn(0);

      } else if(whichPlayersTurn === 1) {

        if(this.gameStateManager.getPlayerNumber() === 0) {
             await this.initializePlayersGameState(whichPlayersTurn);
        }
        this.gameStateManager.setWhichPlayersTurn(whichPlayersTurn);

        if(this.gameController.playIfYourTurn(snapshot.val())) {
          this.setState({"showGameCard":true,"villainsGameClass":"villainsGameHidden"});
          if(this.playBell) {
             this.soundManager.playSprite("gamesounds","bell");
          }

            this.doVibrate(500);
        }

      } else {
        this.gameStateManager.setWhichPlayersTurn(whichPlayersTurn);

        if(this.gameController.playIfYourTurn(snapshot.val())) {
          this.doVibrate(500);
          if(this.playBell) {
            this.soundManager.playSprite("gamesounds","bell");
          }

          this.setState({"showGameCard":true,"villainsGameClass":"villainsGameHidden"});
        }
      }
      // See if the active player is this player.
  };

  doVibrate = (length) => {
    try {
      navigator.vibrate(length);
    } catch(error) {
      console.log("Error vibrating device");
    }

  };


////////////////////////////////////////////////////////////////
// A card being played has resulted in some sort of action so
// act on it.
  gameActionChanged = (snapshot) => {
    var val = snapshot.val();

    if(!this.checkAdminActions(val)) {
     if(this.isCurrentPlayerAffected(val)) {
       console.log("Current player is affected!");

        this.doVibrate([200,200,200]);
        this.playAppropriateSound(val['actionType']);
        this.gameController.playActionCard(val);
        this.setState({"showGameCard":true,"villainsGameClass":"villainsGameHidden"});

     }
   }
  };

  checkAdminActions = (val) => {
    var ret = false;

    if(val.actionType === Actions.BELL_ON || val.actionType === Actions.BELL_OFF) {
       if(val.actionType === Actions.BELL_ON) {
         this.playBell  = true;
       } else {
         this.playBell = false;
       }
       ret = true; // yes it was an admin action
    } else if(val.actionType === Actions.CHAR_SWAP) {
       ret = true;
       console.log("========Someone swapped characters so I need to update");
       var player = val.playerNum;
       var data = val.data;
       console.log("player="+player);
       console.log("name="+data.name+" type="+data.type);
       this.gameController.updatePlayerCharacterSwap(player,data.name,data.type);
    }
    return ret;
  };

  isCurrentPlayerAffected = (data) => {
    var ret = false;
    var currentPlayer = this.gameStateManager.getPlayerNumber();
    var playerAffected = data['playerNum'];
    var player2Affected = data['playerNum2'];
    if(currentPlayer === 0) {
      // This is an extraneous call, likely a result of using joining game, so ignore it.
      ret = false;
    } else if(currentPlayer === playerAffected || (player2Affected > 0 && currentPlayer === player2Affected)) {
      ret = true;
    }
//    console.log("currentPlayer="+currentPlayer+" playerAffected="+playerAffected+" player2Affected="+player2Affected);
    return ret;
  };
//
// Play an evil laugh or a magic sound
  playAppropriateSound = (type) => {
  //  console.log("(playAppropriateSound) type="+type);
    if(type === Actions.RELINQUISH_GIFT_SPELL || type === Actions.MULTI_TRADE_SPELL
       || type === Actions.TRADE_SPELL || type === Actions.VILLAIN_HERO_SWAP ||
          type === Actions.HERO_TO_VILLAIN || type === Actions.SWAP_BODIES_SPELL) {
      var snd = this.getRandomInt(3);
      var nm = "evilLaugh"+snd;
      this.soundManager.playSprite("gamesounds",nm); //
    } else {
      this.playMagicSound();
    }
  };

  playMagicSound=()=> {
    var snd = this.getRandomInt(2);
    var nm = "magic"+snd;
    this.soundManager.playSprite("gamesounds",nm); //
  };

  getRandomInt=(max,nothis=-1)=> {
     var val = 0;
     if(nothis === -1) {
        val = Math.floor(Math.random() * max) + 1;
      } else if(max > 1) {  // to avoid endless loop
        while(val !==nothis) {
          val = Math.floor(Math.random() * max) + 1;
        }
      }
     return val;
  };
//
// Turn is over. if nextPlayer is true then move on to the next player. Otherwise this actions
// was the result of magic or a spell so we really just want to hide the card Because
// another player is actually playing right now.
  completeTurn = (nextPlayer=true) => {
//    console.log("turn is over");
    this.setState({showGameCard:false});
    if(nextPlayer) {
        var whichTurn = this.gameStateManager.getWhichPlayersTurn();
        var prom = this.myFirebase.playerTurnIsComplete(
                this.gameStateManager.getWhichPlayersTurn(),
                this.gameStateManager.getNumberOfPlayers());
        prom.then(()=> {
            console.log("(completeTurn) Player turn complete");
            this.myFirebase.setInfo("Player turn completed: "+whichTurn);
        }).catch((error) => {
          console.log("(completeTurn) Unable to complete turn-Error: "+error);
          this.myFirebase.setError("Could Not Complete Turn: "+error);
        });
    }
  };


  mockGameDataChange = () => {
    console.log("MOCK GAME DATA CHANGE");
  };

  showSomethingWrong = () => {
    var ret = "none";
    if(this.gameStateManager.getPlayerName() === "UNNAMED") {
      ret = "inline-block";
    }
    return ret;
  };

  getCharacterIcon = () => {
     var ret = "/images/bowler_hat_icon.jpg";
     ret = "/images/"+this.gameStateManager.getCharacterIcon();
     return ret;
  };

  render() {
    return(
      <div>

        <div className={this.state.villainsGameClass}>
           <div className="welcomeHeader">Welcome to the Heroes & Villains Game</div>
           <br/>
           Instructions will appear here when it is your turn.<b> DO NOT refresh this page at any time during the game.</b>
           <br/>Until then, relax and have a cocktail.
           <br/>
           The evil villains will be taking over your device soon enough. <b>Bwa ha ha ha</b>. <i>Did I just type that? I meant to think it. Dang it!</i>
        </div>

        <div className="gameCardHolder" style={{display:(this.state.showGameCard) ? 'inline-block':'none'}}>
          <GameCard card={this.gameStateManager.getCard()}
           completeTurnFunc = {this.completeTurn} gsm={this.gameStateManager}
           soundManager={this.soundManager} gameController={this.gameController}/>
        </div>

        <div className="waitingTurnHolder" style={{display:(this.state.showGameCard) ? 'none':'flex'}}>
          Awaiting your next turn...
          <br/>Your turn is important to us. Please stand by....
        </div>

        <div style={{display:this.showSomethingWrong()}}>
           Something is wrong! You'll need to return to the Choose User screen.
           <br/>
           <Link to="/">Return To Choose User</Link>
        </div>

        <div className="mockGameDataChange">
         <div className={this.state.mockDataChangeButtonClass} onClick={this.mockGameDataChange}>Mock Game Data Change</div>
        </div>

        <div id="characterIcon" className="characterIcon" style={{display:(this.state.showGameCard) ? 'none':'block'}}>
          <img src={this.getCharacterIcon()} width="200" alt="Your Character"/>
          <hr/>You are <b> {this.gameStateManager.getCharacter()}</b>
        </div>
      </div>
    );
  }

}

export default withFirebase(VillainsGame);
